<template>
  <b-card>
    <b-row>
      <b-col
        v-for="(item, key) in config"
        :key="key"
        cols="12"
        md="6"
      >
        <b-form-group
          v-if="item.type == 'file'"
          :label="item.name"
          :label-for="item.key"
        >
          <b-form-file
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
          />
        </b-form-group>
        <b-form-group
          v-else-if="item.type == 'select'"
          :label="item.name"
          :label-for="item.key"
        >
          <b-form-select
            v-model="item.value"
            :options="item.meta.select"
            size="md"
          />
        </b-form-group>
        <b-form-group
          v-else-if="item.type == 'number' && item.meta.inputIcon"
          :label="item.name"
          :label-for="item.key"
        >
          <b-input-group>
            <b-form-input
              :type="item.type"
              :placeholder="item.name"
              min="1"
              max="100"
            />
            <b-input-group-append is-text>
              <feather-icon
                :icon="item.meta.inputIcon"
                class="cursor-pointer"
              />
            </b-input-group-append>
          </b-input-group>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <small v-html="item.description" />
        </b-form-group>
        <b-form-group
          v-else-if="item.type == 'editor'"
          :label="item.name"
          :label-for="item.key"
        >
          <quill-editor
            v-model="item.value"
            :options="snowOption"
          />
        </b-form-group>
        <b-form-group
          v-else-if="item.type == 'checkbox'"
          :label="item.name"
          :label-for="item.key"
        >
          <b-form-checkbox
            v-model="item.value"
            class="custom-control-success"
            name="check-button"
            switch
            :value="1"
            :unchecked-value="null"
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <small v-html="item.description" />
        </b-form-group>
        <validation-provider
          v-else-if="item.type == 'password'"
          :name="item.name"
          rules="password"
        >
          <b-form-group :label="item.name">
            <!-- :class="errors.length > 0 ? 'is-invalid':null" -->
            <b-input-group>
              <!-- :state="errors.length > 0 ? false:null" -->
              <b-form-input
                :id="item.key + 'password'"
                v-model="item.value"
                :type="passwordFieldType"
                class="form-control-merge"
                name="login-password"
                :placeholder="item.name"
              />

              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  :icon="passwordToggleIcon"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <small v-html="item.description" />
          </b-form-group>
          <!-- <small class="text-danger">{{ errors[0] }}</small> -->
        </validation-provider>
        <validation-provider
          v-else-if="item.type == 'email'"
          #default="validationContext"
          :name="item.name"
          rules="email"
        >
          <b-form-group
            :label="item.name"
            :label-for="item.key"
          >
            <b-form-input
              :id="item.key"
              v-model="item.value"
              :state="getValidationState(validationContext)"
              trim
              :placeholder="item.name"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <b-form-group
          v-else-if="item.type == 'date'"
          :label="item.name"
          :label-for="item.key"
        >
          <cleave
            id="date"
            v-model="item.value"
            class="form-control"
            :raw="false"
            :options="date"
            placeholder="YYYY-MM-DD"
          />
        </b-form-group>
        <b-form-group
          v-else
          :label="item.name"
          :label-for="item.key"
        >
          <b-form-input
            :id="item.key"
            v-model="item.value"
            :type="item.type"
            trim
            :placeholder="item.name"
          />
          <!-- eslint-disable-next-line vue/no-v-html -->
          <small v-html="item.description" />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        class="mt-50"
      >
        <b-button
          v-if="!loader"
          variant="primary"
          class="mr-1"
          @click="saveSettings()"
        >
          Save Changes
        </b-button>
        <b-button
          v-if="loader"
          variant="primary"
          disabled
          class="mr-1"
        >
          <b-spinner
            small
            type="grow"
          />
          Loading...
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import axios from 'axios'
import Cleave from 'vue-cleave-component'
import { quillEditor } from 'vue-quill-editor'
import formValidation from '@core/comp-functions/forms/form-validation'
import { email, password } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Cleave,
    quillEditor,
  },
  mixins: [togglePasswordVisibility],
  // eslint-disable-next-line vue/require-prop-types
  props: ['config'],
  data() {
    return {
      userForm: {},
      date: {
        date: true,
        delimiter: '-',
        datePattern: ['Y', 'm', 'd'],
      },
      email,
      password,
      snowOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ header: 1 }, { header: 2 }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ direction: 'rtl' }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ['link', 'image'],
          ],
        },
      },
      loader: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  setup() {
    const { getValidationState } = formValidation()

    return {
      getValidationState,
    }
  },
  methods: {
    saveSettings() {
      const payload = this.config.map(item => ({
        key: item.key,
        value: item.value,
      }))
      this.loader = true
      axios.post('configs/update', payload).then(res => {
        if (res.status === 200) {
          this.$toasted.show('Updated Successfully', {
            position: 'bottom-center',
            duration: 3000,
          })
        } else {
          this.$toasted.show('Somthing Went Wrong', {
            position: 'bottom-center',
            duration: 3000,
          })
        }
      }).finally(() => {
        this.loader = false
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>
